import { MutableRefObject, useEffect, useState } from 'react';
import { calculateRows } from 'pages/Chat/utils/calculateRows';

type UseTextareaRowsProps = {
  textAreaRef: MutableRefObject<HTMLTextAreaElement | null>;
  text: string;
  maxRows?: number;
};

export const useTextareaRows = ({
  textAreaRef,
  text,
  maxRows,
}: UseTextareaRowsProps) => {
  const [rows, setRows] = useState(1);

  useEffect(() => {
    const calculatedRows = calculateRows(textAreaRef?.current);
    setRows(maxRows && calculatedRows > maxRows ? maxRows : calculatedRows);
  }, [text, maxRows]);

  return { rows };
};
