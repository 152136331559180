import { calculateContentHeight } from './calculateContentHeight'

export const calculateRows = (textarea?: HTMLTextAreaElement | null) => {
  if (!textarea) {
    return 1
  }

  const style = window.getComputedStyle(textarea)
  const lineHeight = parseInt(style.lineHeight, 10)
  const paddingBottom = parseInt(style.paddingBottom, 10)
  const paddingTop = parseInt(style.paddingTop, 10)
  const paddingY = paddingBottom + paddingTop
  const height = calculateContentHeight(textarea, lineHeight, paddingY)
  const rows = Math.floor(height / lineHeight)

  return rows > 0 ? rows : 1
}
