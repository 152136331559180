import React, { PropsWithChildren } from 'react';

interface UserIconContainerProps extends PropsWithChildren {
  className?: HTMLDivElement['className'];
}
export const UserIconContainer: React.FC<UserIconContainerProps> = ({
  children,
  className,
}) => {
  return (
    <div
      className={`w-10 h-10 rounded-full overflow-hidden flex items-center justify-center font-bold text-lg text-white ${className || ''}`}
    >
      {children}
    </div>
  );
};
