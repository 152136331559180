import React, { PropsWithChildren } from 'react';

interface ChatGroupContainerProps extends PropsWithChildren {
  title: string;
}

export const ChatGroupContainer: React.FC<ChatGroupContainerProps> = ({
  title,
  children,
}) => {
  return (
    <div className="py-2 pb-4">
      <h3 className="text-xs text-gray-400 ">{title}</h3>
      {children}
    </div>
  );
};
