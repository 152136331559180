import React from 'react';

interface BetaBannerProps {}

export const BetaBanner: React.FC<BetaBannerProps> = () => (
  <div className="py-6 px-8 w-full">
    <div className="bg-red-400 text-white text-center p-2 w-full rounded-xl text-sm">
      CAUTION: BETA Version - Expect Potential Failures and Inaccuracies. This
      software is currently in its beta testing phase. Users may encounter
      errors, incomplete features, and inconsistencies in information.
    </div>
  </div>
);
