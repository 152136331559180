import React, { useEffect, useRef, useState } from 'react';
import { SLOW_UPLOAD_DISCLAIMER_TIME } from 'pages/Chat/utils/constants';

interface SlowFileUploadDisclaimerProps {
  isUploading: boolean;
}

export const SlowFileUploadDisclaimer: React.FC<
  SlowFileUploadDisclaimerProps
> = ({ isUploading }) => {
  const [shouldShowUploadDisclaimer, setShouldShowUploadDisclaimer] =
    useState(false);

  const fileUploadDisclaimerTimeout = useRef<NodeJS.Timeout | null>();

  useEffect(() => {
    if (isUploading) {
      fileUploadDisclaimerTimeout.current = setTimeout(() => {
        setShouldShowUploadDisclaimer(true);
      }, SLOW_UPLOAD_DISCLAIMER_TIME);
    } else {
      setShouldShowUploadDisclaimer(false);
    }

    return () => {
      if (fileUploadDisclaimerTimeout.current) {
        clearTimeout(fileUploadDisclaimerTimeout.current);
        fileUploadDisclaimerTimeout.current = null;
      }
    };
  }, [isUploading]);

  if (!shouldShowUploadDisclaimer) {
    return null;
  }

  return (
    <div className="rounded-md bg-gray-400 bg-opacity-20 p-3 text-xs mb-2">
      <p> Still uploading the file... Please, consider that:</p>
      <ul className="list-disc ml-4 mt-2">
        <li>
          The duration of this process is influenced by OCR requirements, number
          of pages and tables and charts within the document
        </li>
        <li>
          If it seems excessively slow, we recommend opting for the &apos;Fast
          Files Processing&apos; via the dropdown menu above or document
          chunking
        </li>
      </ul>
    </div>
  );
};
