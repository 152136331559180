import { ChatsMap } from 'pages/Chat/types';

export const enum LocalStorageKeys {
  CHATS = 'chats',
}

export const getChatsFromLocalstorage = (): ChatsMap => {
  const storageValue = localStorage.getItem(LocalStorageKeys.CHATS);
  if (!storageValue) {
    return {};
  }
  return JSON.parse(storageValue);
};
export const setChatsToLocalstorage = (chats: ChatsMap): void => {
  localStorage.setItem(LocalStorageKeys.CHATS, JSON.stringify(chats));
};
