import React, { PropsWithChildren } from 'react';
import { useMsal } from '@azure/msal-react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute: React.FC<PropsWithChildren> = ({ children }) => {
  const { accounts } = useMsal();

  if (!accounts || accounts.length === 0) {
    // User is not authenticated, redirect to login
    return <Navigate to="/" />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
