import { Message } from 'pages/Chat/types';

export const replaceAttachmentUrls = ({
  text,
  attachments,
}: Pick<Message, 'text' | 'attachments'>): string => {
  if (!attachments?.length) {
    return text;
  }

  let currentIndex = 0;

  let replacedMessage = text.replace(
    /(!?\[.+?])\((?!http.+?\))(.*?)\)/gm,
    (match, altText) => {
      if (currentIndex >= attachments.length) {
        return match;
      }

      const replacementUrl = attachments[currentIndex++].url;

      if (altText.startsWith('!')) {
        return `![${altText.slice(2, -1)}](${replacementUrl})`;
      } else {
        return `[${altText.slice(1, -1)}](${replacementUrl})`;
      }
    },
  );

  // Append any extra images at the end of the message
  while (currentIndex < attachments.length) {
    const attachment = attachments[currentIndex++];

    const isImage = attachment.fileType === 'image';

    if (isImage) {
      replacedMessage += `\n\n![Image](${attachment.url})`;
    } else {
      replacedMessage += `\n\n[File](${attachment.url})`;
    }
  }

  return replacedMessage;
};
//
// export const replaceAttachmentUrls = ({
//   text,
//   attachmentUrls,
// }: Pick<Message, 'text' | 'attachmentUrls'>): string => {
//   if (!attachmentUrls?.length) {
//     return text;
//   }
//
//   let currentIndex = 0;
//
//   let replacedMessage = text.replace(
//     /(!)\[(.+?)]\((?!http.+?\))(.*?)\)/gm,
//     (match, altText, capture) => {
//       if (currentIndex < attachmentUrls.length) {
//         const replacementUrl = attachmentUrls[currentIndex++];
//         return `![${altText}](${replacementUrl})`;
//       } else {
//         return `![Image](${capture})`;
//       }
//     },
//   );
//
//   // Append any extra images at the end of the message
//   while (currentIndex < attachmentUrls.length) {
//     replacedMessage += `\n\n![Image](${attachmentUrls[currentIndex++]})`;
//   }
//
//   return replacedMessage;
// };
