import { useEffect, useState } from 'react';
import {
  LocalStorageKeys,
  setChatsToLocalstorage,
} from 'pages/Chat/services/LocalStorageService';
import { ChatsMap, Message } from 'pages/Chat/types';

const CHAT_TITLES_KEY = 'chatTitles';

// TODO remove this migration
export const useRestructureChats = () => {
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const chatsFromLocalStorage = localStorage.getItem(LocalStorageKeys.CHATS);
    const chatTitlesFromLocalStorage = localStorage.getItem(CHAT_TITLES_KEY);

    if (!chatTitlesFromLocalStorage || !chatsFromLocalStorage) {
      setIsReady(true);
      return;
    }

    const chatsMap_oldFormat: Record<string, Array<Message>> = JSON.parse(
      chatsFromLocalStorage,
    );
    const existingChatTitles: Record<string, string> = JSON.parse(
      chatTitlesFromLocalStorage,
    );

    const chatsEntries_oldFormat = Object.entries(chatsMap_oldFormat);

    // Double check if the format wasn't changed previously
    if (!Array.isArray(chatsEntries_oldFormat[0][1])) {
      setIsReady(true);
      return;
    }

    const newChats = Object.entries(chatsMap_oldFormat).reduce<ChatsMap>(
      (acc, [chatId, messages]) => {
        acc[chatId] = {
          chatId,
          messages,
          chatTitle: existingChatTitles[chatId],
        };
        return acc;
      },
      {},
    );

    setChatsToLocalstorage(newChats);
    localStorage.removeItem(CHAT_TITLES_KEY);
    setIsReady(true);
  }, []);

  return isReady;
};
