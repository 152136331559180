import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';

interface SidebarFooterButtonProps {
  icon: IconDefinition;
  children: string;
  href?: string;
  isExternalLink?: boolean;
  onClick?: () => void;
}

export const SidebarFooterButton: React.FC<SidebarFooterButtonProps> = ({
  children,
  icon,
  isExternalLink,
  href,
  onClick,
}) => {
  const className =
    'text-gray-200 flex-1 p-2 pt-3 hover:bg-white hover:bg-opacity-10 transition-colors flex flex-col items-center';

  const content = (
    <>
      <FontAwesomeIcon icon={icon} size="sm" />
      <span className="text-xs mt-2">{children}</span>
    </>
  );

  if (!href) {
    return (
      <button className={className} onClick={onClick}>
        {content}
      </button>
    );
  }

  const linkProps: React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  > = { href };

  if (isExternalLink) {
    linkProps.target = '_blank';
    linkProps.rel = 'noreferrer';
  }

  return (
    <a className={className} {...linkProps}>
      {content}
    </a>
  );
};
