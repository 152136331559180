import React from 'react';
import ReactMarkdown from 'react-markdown';
import { withErrorBoundary } from 'react-use-error-boundary';
import remarkGfm from 'remark-gfm';
import '../MarkdownStyles.css';

type MessageContentProps = { children?: string; hasError?: boolean };

// Define types for the custom link component props
type LinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement>;

const LinkRenderer: React.FC<LinkProps> = (props) => (
  <a {...props} target="_blank" rel="noopener noreferrer">
    {props.children}
  </a>
);

export const MessageContent: React.FC<MessageContentProps> = withErrorBoundary(
  ({ children }) => {
    // const [error] = useErrorBoundary();
    const cn = 'markdown';

    if (!children) {
      return null;
    }

    return (
      <ReactMarkdown
        className={cn}
        remarkPlugins={[remarkGfm]}
        components={{ a: LinkRenderer }} // Use the custom LinkRenderer for links
      >
        {children}
      </ReactMarkdown>
    );
  },
);
