import React, {
  ChangeEventHandler,
  KeyboardEventHandler,
  useEffect,
  useRef,
  useState,
} from 'react';
import { captureKeyboardInputActions } from 'pages/Chat/utils/forms';
import { ChatOptionsButton } from 'pages/Chat/components/ChatOptionsButton';
import { faEdit, faTrash, faTimes } from '@fortawesome/free-solid-svg-icons';

interface ChatListItemProps {
  chatId: string;
  title: string;
  onSelect: (chatId: string) => void;
  onDelete: (chatId: string) => void;
  onChatOptionsClick: (chatId: string) => void;
  isSelected: boolean;
  shouldAnimate: boolean;
  shouldShowChatOptions: boolean;
  onEditChatTitle: (chatId: string, newTitle: string) => void;
}

export const ChatListItem: React.FC<ChatListItemProps> = ({
  chatId,
  title,
  isSelected,
  onChatOptionsClick,
  shouldAnimate,
  shouldShowChatOptions,
  onSelect,
  onDelete,
  onEditChatTitle,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedTitle, setEditedTitle] = useState('');
  const editInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const element = editInputRef.current;
    if (element) {
      element.focus();
      element.selectionStart = 0;
      element.selectionEnd = element.value.length;
    }
  }, [isEditing]);

  const handleEditClick = () => {
    setEditedTitle(title);
    setIsEditing(true);
  };

  useEffect(() => {
    if (!shouldShowChatOptions) {
      handleCancelEdit();
    }
  }, [shouldShowChatOptions]);

  const handleEditChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setEditedTitle(event.target.value);
  };

  const handleCancelEdit = () => {
    setEditedTitle(title);
    setIsEditing(false);
  };
  const handleSubmitEditing = () => {
    onEditChatTitle(chatId, editedTitle);
    handleCancelEdit();
  };

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    captureKeyboardInputActions({
      event,
      onSubmit: handleSubmitEditing,
      onCancel: handleCancelEdit,
    });
  };

  return (
    <div
      key={chatId}
      className="chat-entry-container relative flex items-center justify-between"
    >
      <div
        role={isEditing ? undefined : 'button'}
        onClick={() => onSelect(chatId)}
        className={`flex justify-start items-center p-2 w-full text-base text-gray-200 text-left hover:bg-white hover:bg-opacity-10 my-1 rounded-lg border-none transition-colors ${isSelected ? 'bg-white bg-opacity-10' : ''}`}
      >
        {isEditing ? (
          <input
            ref={editInputRef}
            value={editedTitle}
            onChange={handleEditChange}
            onKeyDown={handleKeyDown}
            className="flex-1 mr-1 chat-title-text text-sm bg-transparent"
          />
        ) : (
          <span
            className={`overflow-hidden whitespace-nowrap flex-1 mr-1 chat-title-text text-sm ${shouldAnimate ? 'typewriter-effect-chat-title' : ''}`}
          >
            {title}
          </span>
        )}
        <div className="relative" onClick={(e) => e.stopPropagation()}>
          <span
            role="button"
            className="font-bold px-2"
            onClick={() => onChatOptionsClick(chatId)}
          >
            …
          </span>
        </div>
      </div>
      {shouldShowChatOptions && (
      <div className="absolute right-0 bg-white rounded-md top-3/4 z-10 flex flex-col">
        {isEditing ? (
          <ChatOptionsButton
            label="Cancel"
            icon={faTimes}
            onClick={() => handleCancelEdit()}
          />
        ) : (
          <>
            <div className="text-left">
              <ChatOptionsButton
                label="Delete Chat"
                icon={faTrash}
                onClick={() => onDelete(chatId)}
              />
            </div>
            <div className="text-left">
              <ChatOptionsButton
                label="Edit Chat"
                icon={faEdit}
                onClick={() => handleEditClick()}
              />
            </div>
          </>
        )}
      </div>

      )}
    </div>
  );
};
