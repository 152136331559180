import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileArrowUp,
  faFilePen,
  faGlobe,
  faSpinner,
  faPaperclip,
} from '@fortawesome/free-solid-svg-icons';

interface FormInputIconLeftProps {
  isBingSearch: boolean;
  isFileUpload: boolean;
  isFileAnalysis: boolean;
  isSending: boolean;
}

export const FormInputIconLeft: React.FC<FormInputIconLeftProps> = ({
  isBingSearch,
  isFileUpload,
  isFileAnalysis,
  isSending,
}) => {
  return (
    <div className="ml-2">
      {isBingSearch ? (
        <FontAwesomeIcon icon={faGlobe} size="lg" color="#B197FC" bounce />
      ) : isFileUpload ? (
        <FontAwesomeIcon icon={faFileArrowUp} size="lg" color="#FFD43B" fade />
      ) : isFileAnalysis ? (
        <FontAwesomeIcon icon={faFilePen} size="lg" color="#FFD43B" beatFade />
      ) : (
        <>
          {isSending ? (
            <FontAwesomeIcon icon={faSpinner} size="lg" color="#888" spin />
          ) : (
            <FontAwesomeIcon icon={faPaperclip} size="lg" color="#888" />
          )}
        </>
      )}
    </div>
  );
};
