import React from 'react';
import imageInstruction from 'assets/imageInstruction.png';

interface NoChatsPopupProps {
  onClose: () => void;
}

export const NoChatsPopup: React.FC<NoChatsPopupProps> = ({ onClose }) => {
  return (
    <div className="popup-overlay">
      <div className="popup-container">
        <button onClick={onClose} className="popup-close-button">
          &times;
        </button>
        <h1 className="popup-header">Welcome to A&M GenAI Platform</h1>
        <p className="popup-text">
          The following guide will help you to get started with the platform
        </p>
        <img src={imageInstruction} alt="imgIns" className="imgPopup" />
      </div>
    </div>
  );
};
