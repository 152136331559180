import { KeyboardEvent } from 'react';

export const captureKeyboardInputActions = ({
  event,
  canSubmit = true,
  onSubmit,
  onCancel,
}: {
  event: KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>;
  canSubmit?: boolean;
  onSubmit?: () => void;
  onCancel?: () => void;
}) => {
  if (canSubmit && event.key === 'Enter' && !event.shiftKey) {
    onSubmit?.();
  } else if (event.key === 'Escape') {
    onCancel?.();
  }
};
