import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface ChatOptionsButtonProps {
  onClick: () => void;
  icon: IconProp;
  label: string;
}

export const ChatOptionsButton: React.FC<ChatOptionsButtonProps> = ({
  onClick,
  icon,
  label,
}) => {
  return (
    <button className="text-blue-1000 py-1 px-2 text-sm" onClick={onClick}>
      <FontAwesomeIcon icon={icon} className="mx-1" /> {label}
    </button>
  );
};
