import { loginRequest, msalConfig } from 'common/authentication/msalConfig';
import {
  AuthenticationResult,
  EventType,
  PublicClientApplication,
} from '@azure/msal-browser';

/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
export const msalInstance = new PublicClientApplication(msalConfig);
msalInstance.addEventCallback((event) => {
  const payload = event.payload as AuthenticationResult | null;

  try {
    if (event.eventType === EventType.LOGIN_SUCCESS && payload?.account) {
      msalInstance.setActiveAccount(payload.account);
    }
  } catch (error) {
    console.error('Something wrong in msalInstance.addEventCallback - ', error);
  }
});

export const getAccessToken = async (): Promise<string | undefined> => {
  const [account] = msalInstance.getAllAccounts();
  const { accessToken } = await msalInstance.acquireTokenSilent({
    ...loginRequest,
    account,
  });

  return accessToken;
};
