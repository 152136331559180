// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.markdown h1 {
  font-size: 2em;
}

.markdown h2 {
  font-size: 1.5em;
}

.markdown h3 {
  font-size: 1.0em;
}

.markdown p {
  font-size: 0.85rem;
}


.markdown code {
  font-size: 0.85rem;
  overflow-x: auto;
}

.markdown thead {
  font-size: 0.9rem;
  color: #2e3b3d;
  font-weight: bold; /* This makes the text bold */
  font-family: 'Roboto', sans-serif; /* Replace 'Your Font Name' with your desired font */
}

.markdown tbody {
  font-size: 0.85rem;
}

.markdown a {
  font-size: 0.85rem;
  color: #274B71;
}

.markdown strong {
  font-size: 0.9rem;
  color: #2e3b3d;
  font-weight: bold; /* This makes the text bold */
  font-family: 'Roboto', sans-serif; /* Replace 'Your Font Name' with your desired font */
}
`, "",{"version":3,"sources":["webpack://./src/pages/Chat/MarkdownStyles.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB;;;AAGA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,cAAc;EACd,iBAAiB,EAAE,6BAA6B;EAChD,iCAAiC,EAAE,oDAAoD;AACzF;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,iBAAiB;EACjB,cAAc;EACd,iBAAiB,EAAE,6BAA6B;EAChD,iCAAiC,EAAE,oDAAoD;AACzF","sourcesContent":[".markdown h1 {\n  font-size: 2em;\n}\n\n.markdown h2 {\n  font-size: 1.5em;\n}\n\n.markdown h3 {\n  font-size: 1.0em;\n}\n\n.markdown p {\n  font-size: 0.85rem;\n}\n\n\n.markdown code {\n  font-size: 0.85rem;\n  overflow-x: auto;\n}\n\n.markdown thead {\n  font-size: 0.9rem;\n  color: #2e3b3d;\n  font-weight: bold; /* This makes the text bold */\n  font-family: 'Roboto', sans-serif; /* Replace 'Your Font Name' with your desired font */\n}\n\n.markdown tbody {\n  font-size: 0.85rem;\n}\n\n.markdown a {\n  font-size: 0.85rem;\n  color: #274B71;\n}\n\n.markdown strong {\n  font-size: 0.9rem;\n  color: #2e3b3d;\n  font-weight: bold; /* This makes the text bold */\n  font-family: 'Roboto', sans-serif; /* Replace 'Your Font Name' with your desired font */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
