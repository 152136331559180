import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { MsalProvider } from '@azure/msal-react';
import { msalInstance } from 'common/authentication/msalInstance';

const rootHtmlElement = document.getElementById('root');

if (rootHtmlElement) {
  const root = ReactDOM.createRoot(rootHtmlElement);

  root.render(
    <React.StrictMode>
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>
    </React.StrictMode>,
  );
}
