//To handle the profile and name shown in the app
export const getInitials = (username: string): string => {
  if (!username) return '';

  const parts = username.split(',').map((part) => part.trim());
  if (parts.length === 2) {
    // Assuming format "LastName, FirstName"
    const lastNameInitial = parts[0].charAt(0);
    const firstNameInitial = parts[1].charAt(0);
    return `${firstNameInitial}${lastNameInitial}`.toUpperCase();
  } else {
    // Fallback for other formats
    return username.substring(0, 2).toUpperCase();
  }
};

export const getShortenedUsername = (username: string): string => {
  if (!username) {
    return ''; // Return an empty string if input is invalid
  }

  const parts = username.split(',').map((part) => part.trim());
  if (parts.length === 2) {
    // Assuming format "LastName, FirstName"
    // Extracting the first part of the last name (in case of multiple last names)
    const lastNamePart = parts[0].split(' ')[0];
    const firstName = parts[1];
    return `${firstName} ${lastNamePart}`;
  } else {
    // Fallback for other formats
    return username;
  }
};
