import React, {
  ChangeEventHandler,
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import './Chat.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRight,
  faBars,
  faStop,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { v4 as uuidv4 } from 'uuid';
import { Tooltip } from 'react-tooltip';

import {
  AiModel,
  ChatItem,
  ChatsMap,
  Message,
  MessageAttachment,
  SelectOption,
  StreamingEvent,
  UseCase,
} from 'pages/Chat/types';
import {
  getChatsFromLocalstorage,
  setChatsToLocalstorage,
} from 'pages/Chat/services/LocalStorageService';
import { ChatMessage } from 'pages/Chat/components/ChatMessage/ChatMessage';
import { useAuthContext } from 'common/authentication/AuthProvider';
import { BetaBanner } from 'pages/Chat/components/BetaBanner';
import {
  sendFile,
  sendStreamingMessage,
  sendToSqlAgent,
} from 'pages/Chat/services/chatApiService';
import { ChatSidebar } from 'pages/Chat/Sidebar/ChatSidebar';
import { FormInputIconLeft } from 'pages/Chat/components/FormInputIconLeft';
import { NoChatsPopup } from 'pages/Chat/components/NoChatsPopup';
import { ChatsHeader } from 'pages/Chat/components/ChatsHeader';
import { aiModelOptions } from 'pages/Chat/utils/aiModelOptions';
import { useDebugOnWindow } from 'pages/Chat/hooks/useDebugOnWindow';
import { SlowFileUploadDisclaimer } from 'pages/Chat/components/SlowFileUploadDisclaimer';
import { replaceAttachmentUrls } from 'pages/Chat/utils/replaceAttachmentUrls';
import { useTextareaRows } from 'pages/Chat/hooks/useTextareaRows';

function Chat() {
  //Using context of the app
  const { userInfo } = useAuthContext();

  const inputRef = useRef<HTMLTextAreaElement | null>(null);

  const [input, setInput] = useState('');

  const { rows } = useTextareaRows({
    textAreaRef: inputRef,
    text: input,
    maxRows: 5,
  });

  const [currentChats, setCurrentChats] = useState<ChatsMap>({});

  const chatContainerRef = useRef<HTMLDivElement | null>(null);

  const scrollToBottom = useCallback(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, []);

  const [isNewChatStarted, setIsNewChatStarted] = useState(false);
  // const [previousChats, setPreviousChats] = useState<ChatsArray>([]);
  const [selectedChatId, setSelectedChatId] = useState<string | null>(null);

  const selectedChat = useMemo(
    () => (selectedChatId ? currentChats[selectedChatId] : null),
    [currentChats, selectedChatId],
  );

  //For showing initial instructions popup
  const [showNoChatPopup, setShowNoChatPopup] = useState(false);

  const [triggerTypewriter, setTriggerTypewriter] = useState(false);

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isSending, setIsSending] = useState(false);

  //For bing search or file upload status
  const [isBingSearch, setIsBingSearch] = useState(false);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [isFileAnalysis, setIsFileAnalysis] = useState(false);
  const [streamingChatId, setStreamingChatId] = useState<string | null>(null);

  const setChatTitle = (chatId: string, title: string) => {
    setCurrentChats((prevState) => {
      const newChats = {
        ...prevState,
        [chatId]: {
          ...prevState[chatId],
          chatTitle: title,
        },
      };

      setChatsToLocalstorage(newChats);

      return newChats;
    });
  };

  useEffect(() => {
    if (!streamingChatId || streamingChatId === selectedChatId) {
      scrollToBottom();
    }
  }, [selectedChat, scrollToBottom, streamingChatId, selectedChatId]);

  // placeholder text for the input
  const [placeholderText, setPlaceholderText] = useState('Send a message...');

  //For uploading of files
  const [selectedFiles, setSelectedFiles] = useState<Array<File>>([]);

  const selectedFileNames = useMemo(
    () => selectedFiles.map((file) => file.name),
    [selectedFiles],
  );

  // const [chatFiles, setChatFiles] = useState<Array<File>>([]); //Storing all files uploaded

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [completionAbortController, setCompletionAbortController] =
    useState<AbortController>();
  const currentResponseReader = useRef<
    ReadableStreamDefaultReader<Uint8Array> | undefined
  >();

  // const [lastMessageIndex, setLastMessageIndex] = useState<number | null>(null);

  // On component mount - load all chats from localStorage
  useEffect(() => {
    const existingChats = getChatsFromLocalstorage();

    if (!existingChats) {
      return;
    }

    const chantEntries = Object.entries(existingChats);

    let hasMissingId = false;

    const updatedChats = chantEntries.reduce((acc, [chatId, chat]) => {
      acc[chatId] = chat;

      acc[chatId].messages = chat.messages.map((message) => {
        if (!message.id) {
          message.id = uuidv4();
          hasMissingId = true;
        }

        return message;
      });
      return acc;
    }, {} as ChatsMap);

    if (hasMissingId) {
      setChatsToLocalstorage(updatedChats);
      setCurrentChats(updatedChats);
    }
  }, []);

  // TODO check if this useEffect is needed - I remove dit since the state wasn't used anywhere
  // useEffect(() => {
  //   if (messages.length > 0) {
  //     setLastMessageIndex(messages.length - 1);
  //   }
  // }, [messages]);

  const [selectedUseCase, setSelectedUseCase] = useState<UseCase>('fast');
  const [selectedAiModel, setSelectedAiModel] = useState<SelectOption<AiModel>>(
    aiModelOptions[2],
  );
  const [useAssistantApi, setUseAssistantsApi] = useState(false);

  useEffect(() => {
    setUseAssistantsApi(!!selectedChat?.threadId);
  }, [selectedChat?.threadId]);

  const handleOpenPopup = () => {
    setShowNoChatPopup(true);
  };

  // Function to close the popup
  const handleClosePopup = () => {
    setShowNoChatPopup(false);
  };

  const hasValidChats = (chats: ChatsMap) => {
    return Object.entries(chats).some(
      ([key, { messages }]) => key && messages.length > 0,
    );
  };

  const addMessageToChat = (
    previousChats: ChatsMap,
    chatId: string,
    message: Message,
  ): ChatsMap => {
    const newChats: ChatsMap = {
      ...previousChats,
      [chatId]: {
        ...previousChats[chatId],
        messages: [...previousChats[chatId].messages],
      },
    };

    newChats[chatId].messages.push(message);

    return newChats;
  };

  const updateMessageInChat = (
    previousChats: ChatsMap,
    chatId: string,
    message: Message,
    index: number,
  ): ChatsMap => {
    if (!previousChats[chatId]) {
      throw new Error('Incorrect chatId provided');
    }

    const newChats: ChatsMap = {
      ...previousChats,
      [chatId]: {
        ...previousChats[chatId],
        messages: [...previousChats[chatId].messages],
      },
    };

    newChats[chatId].messages[index] = message;

    return newChats;
  };

  // Generate new chat ID on "New Chat" button click
  const handleNewChat = () => {
    const newChatId = uuidv4();
    setIsNewChatStarted(true);
    //Setting the welcome message

    const originalMessage =
      'Welcome to A&M ChatGPT, a secured GenAI platform that prioritizes the highest standards of security and data privacy:\n- Firstly, we ensure that all information remains within a secured Azure instance.\n- Secondly, we use Azure-based GenAI models specifically designed not to use any data shared by users, thereby safeguarding personal and sensitive information.\n- Additionally, we enable Active Directory secured access to the platform.';

    const messages = [
      {
        id: uuidv4(),
        text: originalMessage,
        isBot: true,
        timestamp: new Date().toISOString(),
        isStreaming: false,
      },
    ];

    const newChat: ChatItem = {
      chatId: newChatId,
      chatTitle: 'Placeholder',
      messages,
    };

    const storedChats = getChatsFromLocalstorage();
    storedChats[newChatId] = newChat;
    setChatsToLocalstorage(storedChats);

    setCurrentChats(storedChats);
    setSelectedChatId(newChatId);

    // setChatTitle(newChatId, 'Placeholder');
    //Clearing all files uploaded to other chats
    // setChatFiles([]);
    setSelectedFiles([]);

    /* Other configs */
    setSelectedAiModel(aiModelOptions[2]); // Assuming this is the default value
    setSelectedUseCase('fast'); // Assuming this is the default value
    // reloadAndSelectFirstChat();
  };

  const handleDeleteChat = (chatId: string) => {
    // Retrieve stored chats and chat titles from localStorage
    const storedChats = getChatsFromLocalstorage();

    // Remove the chat ID from both storedChats and chatTitles
    delete storedChats[chatId];

    // Clean up localStorage to remove empty chats and invalid keys
    // cleanUpLocalStorage(storedChats);

    setChatsToLocalstorage(storedChats);
    setSelectedChatId(null);
    setCurrentChats(storedChats);
  };

  // Load previous chats from localStorage when the component mounts
  useEffect(() => {
    reloadAndSelectFirstChat();
  }, []);

  // TODO: refactor this function
  const reloadAndSelectFirstChat = () => {
    const storedChats = getChatsFromLocalstorage();
    // Show popup if no chats
    if (Object.keys(storedChats).length === 0) {
      setShowNoChatPopup(true); // Show popup if no chats
    }
    const chatsEntries = Object.entries(storedChats); // Convert object to array for easier mapping

    // TODO: avoid code duplicate (this sorting function is also called in ChatSidebar
    // Sort chats based on the timestamp of the last message
    chatsEntries.sort((a, b) => {
      const chatA = a[1];
      const chatB = b[1];
      const lastMessageA = chatA.messages[chatA.messages.length - 1];
      const lastMessageB = chatB.messages[chatB.messages.length - 1];
      if (
        lastMessageA &&
        lastMessageB &&
        lastMessageA.timestamp &&
        lastMessageB.timestamp
      ) {
        return (
          new Date(lastMessageB.timestamp).getTime() -
          new Date(lastMessageA.timestamp).getTime()
        );
      }
      return 0;
    });

    // TODO: check if the refactored version from above is working properly
    // setPreviousChats(
    //   chatsEntries.map(([chatId, messages]) => [
    //     chatId,
    //     messages,
    //     chatTitles[chatId] || '',
    //   ]),
    // );

    // Set the first valid chat entry
    const validChatEntry = chatsEntries.find(
      ([chatId, chatItem]) => chatId && chatItem.messages.length > 0,
    );
    if (validChatEntry) {
      const [latestChatId] = validChatEntry;
      setSelectedChatId(latestChatId);
      setCurrentChats(storedChats);
    }

    setIsNewChatStarted(hasValidChats(storedChats));
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  //Format the conversation history
  const formatConversationHistory = (
    messages: Array<Message> | null,
  ): string => {
    if (!messages) {
      return '';
    }

    // Check if the first message is the default welcome message
    const isDefaultWelcomeMessage =
      messages.length > 0 &&
      messages[0].text.startsWith('Welcome to A&M ChatGPT');

    // Start from the second message if the first message is the default welcome message
    const startIndex = isDefaultWelcomeMessage ? 1 : 0;

    // Map starting from startIndex
    return messages
      .slice(startIndex)
      .map((message) => `${message.isBot ? 'AI' : 'Human'}: ${message.text}`)
      .join('\n');
  };

  const handleFileChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    if (!event.target.files) {
      return;
    }

    const newFilesArray = Array.from(event.target.files).filter(
      (newFile) =>
        !selectedFiles.find(
          (existingFile) =>
            existingFile.name === newFile.name &&
            existingFile.type === newFile.type &&
            existingFile.size === newFile.size &&
            existingFile.lastModified === newFile.lastModified,
        ),
    );

    const allFilesArray = [...selectedFiles, ...newFilesArray];

    setSelectedFiles(allFilesArray);
  };

  // Count the number of user messages (excluding bot messages)
  const userMessageCount = useMemo<number>(() => {
    return (
      selectedChat?.messages.filter((message) => !message.isBot).length || 0
    );
  }, [selectedChat?.messages]);

  // Function to handle file removal
  const handleFileDelete = (index: number) => {
    // Now this should work since selectedFiles is an array
    const newCompleteFiles = selectedFiles.filter(
      (_, fileIndex) => fileIndex !== index,
    );
    setSelectedFiles(newCompleteFiles);

    // // Remove from the chatFiles list if it hasn't been sent
    // const fileToRemove = selectedFiles[index];
    // // setChatFiles((prevFiles) =>
    //   prevFiles.filter((file) => file !== fileToRemove),
    // );

    // If you're using a ref to the file input, you need to clear it as well
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const isSendDisabled =
    (!input && selectedFiles.length === 0) || isSending || !!streamingChatId;

  const handleSend = async () => {
    if (isSendDisabled || !selectedChatId || !selectedChat) {
      return;
    }

    const messages = selectedChat.messages;
    const inputTrimmed = input.trim(); // Get the trimmed text from the input

    // Check if the input is empty or if there are no uploaded files
    if (!inputTrimmed && selectedFiles.length === 0) {
      setIsSending(false);
      return;
    }

    // Start spinner for loading
    setIsSending(true);

    // Flag to indicate if it's the first message sent by the user
    const isFirstUserMessage = userMessageCount === 0;

    //Format the conversation history
    const conversationHistory = formatConversationHistory(
      selectedChat.messages,
    );

    //Generate same timestamp for the messages
    const timestamp = new Date().toISOString();

    // If there's text from the user, add it as a message
    // This adds the message that the user has sent to get info
    if (inputTrimmed) {
      const newMessage: Message = {
        id: uuidv4(),
        text: inputTrimmed,
        isBot: false,
        timestamp: timestamp,
        isStreaming: false,
      };

      // Append the new message content to the existing messages, which is coming directly from the user
      const updatedChats = addMessageToChat(
        getChatsFromLocalstorage(),
        selectedChatId,
        newMessage,
      );
      setChatsToLocalstorage(updatedChats);
      setCurrentChats(updatedChats);
    }

    //Clear the input field
    setInput('');

    //Check if there are uploaded files to send to the other function
    if (
      !useAssistantApi &&
      selectedFiles.length > 0 &&
      selectedUseCase != 'automated'
    ) {
      await handleFileSend(
        selectedChatId,
        inputTrimmed,
        isFirstUserMessage,
        timestamp,
        conversationHistory,
        selectedFiles,
      );
    } else if (selectedUseCase === 'procurementSql') {
      await handleProcurementSend(
        selectedChatId,
        inputTrimmed,
        isFirstUserMessage,
        timestamp,
        conversationHistory,
        selectedFiles,
      );
    } else {
      await sendMessage({
        isFirstUserMessage,
        text: inputTrimmed,
        existingMessages: messages,
        conversationHistory,
        timestamp,
        files: selectedFiles,
      });
    }
  };

  const sendMessage = async ({
    existingMessages,
    text,
    isFirstUserMessage,
    conversationHistory,
    timestamp,
    files,
  }: {
    existingMessages: Array<Message>;
    text: string;
    isFirstUserMessage: boolean;
    conversationHistory: string;
    timestamp: string;
    files?: Array<File>;
  }) => {
    if (!selectedChatId) {
      return;
    }
    const messages = existingMessages;
    // Start spinner for loading
    setIsSending(true);
    try {
      const abortController = new AbortController();
      setCompletionAbortController(abortController);

      const chatFileNames = messages.reduce<string[]>((acc, message) => {
        if (message.fileNames?.length) {
          return [...acc, ...message.fileNames];
        }

        return acc;
      }, []);

      // For other use cases, prepare FormData
      const response = await sendStreamingMessage({
        chatId: selectedChatId,
        username: userInfo?.username || '',
        useCase: useAssistantApi ? 'assistants_api' : selectedUseCase,
        text,
        model: selectedAiModel.value,
        isFirstUserMessage,
        conversationHistory,
        timestamp,
        chatFileNames,
        abortController,
        files,
        fileIds: selectedChat?.fileIds,
        threadId: selectedChat?.threadId,
      });

      setCompletionAbortController(undefined);

      setStreamingChatId(selectedChatId);

      const reader = response.body?.getReader();
      currentResponseReader.current = reader;
      const decoder = new TextDecoder();
      let incompleteChunk = '';

      //Set files in the sidebar if there is a correct response
      //setChatFiles(prevFiles => [...prevFiles, ...selectedFiles]);

      const attachments: Array<MessageAttachment> = [];

      const processChunk = async () => {
        if (!reader) {
          return;
        }
        const { done, value } = await reader.read();
        if (done) {
          currentResponseReader.current = undefined;
          console.log('Stream complete');
          setStreamingChatId(null);
          return;
        }
        const chunk = incompleteChunk + decoder.decode(value, { stream: true });
        // Split the chunk by the custom delimiter
        console.log('Chunk: ', chunk);

        const rawChunks = chunk.split('data: ').filter((chunk) => chunk.trim());

        console.log('Raw chunks: ', rawChunks);
        // Remove 'Action: Bing Search' from the first chunk without replacing

        const existingFileIdsSet =
          selectedChat?.fileIds?.reduce<Set<string>>((acc, fileId) => {
            acc.add(fileId);
            return acc;
          }, new Set()) || new Set();

        rawChunks.forEach((rawChunk) => {
          try {
            const jsonObject: StreamingEvent = JSON.parse(rawChunk);
            console.log('Event: ', jsonObject);

            if (jsonObject.operation === 'Bing Search') {
              setIsBingSearch(true);
            }

            if (jsonObject.action === 'Files Analysis') {
              setIsFileAnalysis(true);
            }

            // TODO: move to a different function
            const previousChats = getChatsFromLocalstorage();
            const previousMessages = previousChats[selectedChatId].messages;

            // const updatedMessages = [...previousMessages];
            const lastMessageIndex = previousMessages.length - 1;
            const lastMessage = previousMessages[lastMessageIndex];
            const appResponseChunkText = jsonObject.appResponse;

            const shouldUpdateLastMessage =
              lastMessage?.isBot && lastMessage?.isStreaming;
            const isStreamingEvent = jsonObject.event === 'streaming';

            let updatedChats: ChatsMap | null = null;

            if (jsonObject.attachment) {
              attachments.push(jsonObject.attachment);
            }

            if (shouldUpdateLastMessage || jsonObject.attachment) {
              updatedChats = updateMessageInChat(
                previousChats,
                selectedChatId,
                {
                  ...lastMessage,
                  text: lastMessage.text + appResponseChunkText,
                  isStreaming: isStreamingEvent,
                  attachments,
                },
                lastMessageIndex,
              );
            } else if (appResponseChunkText) {
              updatedChats = addMessageToChat(previousChats, selectedChatId, {
                id: uuidv4(),
                text: appResponseChunkText,
                isBot: true,
                timestamp: new Date().toISOString(),
                isStreaming: isStreamingEvent,
                attachments,
              });
            }

            if (updatedChats) {
              if (jsonObject.threadId) {
                updatedChats[selectedChatId].threadId = jsonObject.threadId;
              }
              if (jsonObject.filesId) {
                jsonObject.filesId.forEach((fileId) =>
                  existingFileIdsSet.add(fileId),
                );

                updatedChats[selectedChatId].fileIds =
                  Array.from(existingFileIdsSet);
              }
              setChatsToLocalstorage(updatedChats);
              setCurrentChats(updatedChats);
            }

            if (jsonObject.chatTitle && jsonObject.chatTitle.trim() !== '') {
              setChatTitle(selectedChatId, jsonObject.chatTitle);

              // Set typewriter to true
              setTriggerTypewriter(true);
            }
          } catch (e) {
            // If JSON parsing fails, assume it's an incomplete chunk
            incompleteChunk = rawChunk;
          }
        });
        await processChunk();
      };
      await processChunk();
      currentResponseReader.current = undefined;

      setTimeout(() => {
        if (attachments.length) {
          replaceAttachmentUrlsInLastMessage();
        }
      });

      //Clear variables for files and the loader
      //setIsSending(false);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setCompletionAbortController(undefined);
      setSelectedFiles([]);
      setStreamingChatId(null);
      setIsSending(false);
      setIsFileAnalysis(false);
      setIsBingSearch(false);
    }
  };

  const replaceAttachmentUrlsInLastMessage = () => {
    if (!selectedChatId) {
      return;
    }
    const previousChats = getChatsFromLocalstorage();
    const previousMessages = previousChats[selectedChatId].messages;

    // const updatedMessages = [...previousMessages];
    const lastMessageIndex = previousMessages.length - 1;
    const lastMessage = previousMessages[lastMessageIndex];

    const updatedText = replaceAttachmentUrls(lastMessage);

    const updatedChats = updateMessageInChat(
      previousChats,
      selectedChatId,
      {
        ...lastMessage,
        text: updatedText,
        originalText: lastMessage.text,
      },
      lastMessageIndex,
    );

    setCurrentChats(updatedChats);
    setChatsToLocalstorage(updatedChats);
  };

  const [fileUploadStatus, setFileUploadStatus] = useState<string>('');

  useEffect(() => {
    // TODO: add the status on the UI when the event stream will send more than one single event per upload
    console.log(fileUploadStatus);
  }, [fileUploadStatus]);

  const handleFileSend = async (
    chatId: string,
    text: string,
    isFirstUserMessage: boolean,
    timestamp: string,
    conversationHistory: string,
    files: Array<File>,
  ) => {
    setIsFileUploading(true);

    try {
      const response = await sendFile({
        chatId,
        username: userInfo?.username || '',
        useCase: selectedUseCase,
        text,
        model: selectedAiModel.value,
        isFirstUserMessage,
        conversationHistory,
        files,
        timestamp,
      });

      const reader = response.body?.getReader();
      currentResponseReader.current = reader;
      const decoder = new TextDecoder();

      let lastResponse = '';

      const processChunk = async () => {
        if (!reader) {
          return;
        }
        const { done, value } = await reader.read();

        if (done) {
          currentResponseReader.current = undefined;
          console.log('Stream complete');
          setStreamingChatId(null);
          return;
        }

        const chunk = decoder.decode(value, { stream: true });
        // Split the chunk by the custom delimiter
        console.log('Chunk: ', chunk);

        const rawChunks = chunk.split('data: ').filter((chunk) => chunk.trim());

        console.log('Raw chunks: ', rawChunks);
        // Remove 'Action: Bing Search' from the first chunk without replacing

        rawChunks.forEach((rawChunk) => {
          try {
            const jsonObject: StreamingEvent = JSON.parse(rawChunk);
            setFileUploadStatus(jsonObject.appResponse);
            lastResponse = jsonObject.appResponse;
            console.log('Event: ', jsonObject);
          } catch (error) {
            console.log(error);
          }
        });
        await processChunk();
      };
      await processChunk();

      handleUploadApiSuccess(chatId, lastResponse, timestamp, files);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsFileUploading(false);
      setIsSending(false);
      setFileUploadStatus('');
    }
  };

  // TODO: find a way to get rid of the timestamp argument.
  const handleUploadApiSuccess = (
    chatId: string,
    text: string,
    timestamp: string,
    files: Array<File>,
  ) => {
    const uploadedFileNames = files.map((file) => file.name);

    setCurrentChats((previousChats) => {
      const newChats = addMessageToChat(previousChats, chatId, {
        id: uuidv4(),
        text,
        isBot: true,
        timestamp: timestamp,
        isStreaming: false,
        fileNames: uploadedFileNames.length ? uploadedFileNames : undefined,
      });

      setChatsToLocalstorage(newChats);

      return newChats;
    });
    // setMessages((prevMessages) => {
    //   const newMessages = [
    //     ...prevMessages,
    //     {
    //       text: responseData.appResponse,
    //       isBot: true,
    //       timestamp: timestamp,
    //       isStreaming: false,
    //       fileNames: selectedFileNames.length ? selectedFileNames : undefined,
    //     },
    //   ];
    //
    //   setMessageToLocalStorage(chatId, messages);
    //
    //   return newMessages;
    // });

    //Clear variables for files and the loader
    setIsFileAnalysis(false);
    setSelectedFiles([]);
  };

  const handleProcurementSend = async (
    chatId: string,
    text: string,
    isFirstUserMessage: boolean,
    timestamp: string,
    conversationHistory: string,
    files: Array<File>,
  ) => {
    setIsFileUploading(true);

    try {
      // For other use cases, prepare FormData
      const response = await sendToSqlAgent({
        chatId,
        username: userInfo?.username || '',
        useCase: selectedUseCase,
        text,
        model: selectedAiModel.value,
        isFirstUserMessage,
        conversationHistory,
        files,
        timestamp,
      });

      handleUploadApiSuccess(chatId, response, timestamp, files);
    } catch (error) {
      console.error('Error:', error);
      setIsSending(false);
      setIsFileUploading(false);
    }
  };

  useDebugOnWindow({ selectedChat });

  const handleEnter: KeyboardEventHandler<HTMLTextAreaElement> = async (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      await handleSend();
    }
  };

  const handleSelectChat = (chatId: string) => {
    //Clear the uploaded documents
    // setChatFiles([]);
    setSelectedFiles([]);
    setTriggerTypewriter(false);

    const storedChats = getChatsFromLocalstorage();
    setCurrentChats((previousChats) => ({
      ...previousChats,
      [chatId]: storedChats[chatId],
    }));
    // const selectedMessages = storedChats[chatId] || [];
    // setMessages(selectedMessages);
    setSelectedChatId(chatId);
  };

  const updateMessageWithTypingEffect = (timestamp: string) => {
    const chatsFromLocalStorage = getChatsFromLocalstorage();
    if (!selectedChatId || !chatsFromLocalStorage[selectedChatId]) {
      return;
    }

    chatsFromLocalStorage[selectedChatId].messages = chatsFromLocalStorage[
      selectedChatId
    ].messages.map((msg) =>
      msg.timestamp === timestamp
        ? { ...msg, hasTypingEffectApplied: true }
        : msg,
    );

    setCurrentChats(chatsFromLocalStorage);
    setChatsToLocalstorage(chatsFromLocalStorage);
  };

  const onTypingComplete = () => {
    setIsSending(false);
  };

  useEffect(() => {
    if (isBingSearch) {
      setPlaceholderText('Searching the internet...');
    } else if (isFileUploading) {
      setPlaceholderText('Uploading files...');
    } else if (isFileAnalysis) {
      setPlaceholderText('Browsing the files...');
    } else {
      // Set a default placeholder text or handle other conditions as needed
      setPlaceholderText('Send a message...');
    }
  }, [isBingSearch, isFileUploading, isFileAnalysis]);

  const handleChange: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    setInput(event.target.value);
  };

  const isPromptInputDisabled =
    selectedUseCase !== 'automated' &&
    !useAssistantApi &&
    (!isNewChatStarted || selectedFiles.length > 0);

  const isFileUploadDisabled =
    selectedUseCase !== 'automated' &&
    !useAssistantApi &&
    input.trim().length > 0;

  useEffect(() => {
    if (selectedChatId && !isPromptInputDisabled) {
      inputRef.current?.focus();
    }
  }, [selectedChatId, isPromptInputDisabled]);

  const handleStopClick = async () => {
    completionAbortController?.abort();
    currentResponseReader.current?.cancel();
    setStreamingChatId(null);
  };

  const handleEditMessage = async (message: Message, newValue: string) => {
    const existingMessages = selectedChat?.messages;

    if (!selectedChatId || !existingMessages) {
      return;
    }

    const messageIndex = existingMessages.findIndex(
      (msg) => msg.id === message.id,
    );

    if (messageIndex === -1) {
      return;
    }

    const updatedMessages = [...existingMessages].splice(0, messageIndex);
    const conversationHistory = formatConversationHistory(updatedMessages);

    const updatedChats = addMessageToChat(
      {
        ...currentChats,
        [selectedChatId]: {
          ...currentChats[selectedChatId],
          messages: updatedMessages,
        },
      },
      selectedChatId,
      { ...message, text: newValue },
    );
    setChatsToLocalstorage(updatedChats);
    setCurrentChats(updatedChats);

    await sendMessage({
      existingMessages: updatedMessages,
      conversationHistory,
      text: newValue,
      isFirstUserMessage: false,
      timestamp: new Date().toISOString(),
    });
  };

  const shouldShowStopButton = !!completionAbortController || !!streamingChatId;

  return (
    <div className="App">
      <div className="hamburger-menu" onClick={toggleSidebar}>
        <FontAwesomeIcon icon={faBars} />
      </div>
      <ChatSidebar
        onSelectChat={handleSelectChat}
        isOpen={sidebarOpen}
        onNewChatClick={handleNewChat}
        onDeleteChat={handleDeleteChat}
        selectedChatId={selectedChatId}
        setIsOpen={setSidebarOpen}
        triggerTypewriter={triggerTypewriter}
        chats={currentChats}
        onEditChatTitle={setChatTitle}
        onOpenPopupClick={handleOpenPopup}
      />

      <div className="flex-1 max-h-lvh flex flex-col items-center">
        <div className="max-w-6xl flex flex-col h-full overflow-hidden">
          {showNoChatPopup && <NoChatsPopup onClose={handleClosePopup} />}
          <ChatsHeader
            selectedAiModel={selectedAiModel}
            onAiModelSelect={setSelectedAiModel}
            selectedUseCase={selectedUseCase}
            onUseCaseSelect={setSelectedUseCase}
            useAssistantApi={useAssistantApi}
            setUseAssistantsApi={setUseAssistantsApi}
            isChatStarted={userMessageCount > 0}
          />

          <div
            className="flex-1 overflow-y-scroll scroll-smooth"
            ref={chatContainerRef}
          >
            <BetaBanner />

            {selectedChat?.messages.map((message, i) => (
              <ChatMessage
                key={i}
                message={message}
                // isBot={message.isBot}
                // text={message.text}
                // timestamp={message.timestamp}
                // isStreaming={message.isStreaming}
                isFirstMessage={i === 0}
                updateMessageWithTypingEffect={updateMessageWithTypingEffect}
                scrollToBottom={scrollToBottom}
                onTypingComplete={onTypingComplete}
                onEdit={handleEditMessage}
                canSubmitEdit={!shouldShowStopButton}
              />
            ))}
          </div>

          {isNewChatStarted && (
            <div className="relative pb-4 px-8">
              <SlowFileUploadDisclaimer isUploading={isFileUploading} />
              {selectedFileNames.length > 0 && (
                <div className="w-full flex justify-start flex-wrap gap-3 mt-3">
                  {selectedFileNames.map((file, index) => (
                    <div
                      key={index}
                      className="mb-3 px-2 py-1 bg-gray-200 rounded-md flex items-center max-w-80 overflow-hidden text-xs"
                    >
                      <span className="whitespace-nowrap overflow-hidden text-ellipsis mr-2 pl-1">
                        {file}
                      </span>
                      <FontAwesomeIcon
                        icon={faTimes}
                        onClick={() => handleFileDelete(index)}
                        className="cursor-pointer text-gray-400"
                      />
                    </div>
                  ))}
                </div>
              )}
              <div className="relative w-full max-w-chatWidth p-2 bg-white flex items-center rounded-xl border border-gray-300 mx-auto">
                <label
                  htmlFor="fileInput"
                  className={`${isFileUploadDisabled ? 'cursor-not-allowed opacity-50 pointer-events-none' : 'cursor-pointer'}`}
                  style={{ padding: '10px' }}
                  data-tip
                  data-for="uploadTip"
                >
                  <input
                    id="fileInput"
                    type="file"
                    onChange={handleFileChange}
                    onClick={(event) => (event.currentTarget.value = '')}
                    multiple
                    disabled={isFileUploadDisabled}
                  />
                  <FormInputIconLeft
                    isBingSearch={isBingSearch}
                    isFileUpload={isFileUploading}
                    isFileAnalysis={isFileAnalysis}
                    isSending={isSending}
                  />
                </label>
                <Tooltip id="uploadTip" place="top">
                  Clear the message to upload files.
                </Tooltip>
                <textarea
                  ref={inputRef}
                  autoFocus
                  rows={rows}
                  placeholder={placeholderText}
                  value={input}
                  onKeyDown={handleEnter}
                  onChange={handleChange}
                  className={`w-full max-w-full resize-none p-2.5 rounded-xl  leading-normal focus:outline-0 shadow-sticky text-sm ${isPromptInputDisabled ? 'text-gray-400' : ''}`}
                  disabled={isPromptInputDisabled}
                />
                {shouldShowStopButton ? (
                  <button
                    className="send"
                    style={{ padding: '10px' }}
                    onClick={handleStopClick}
                  >
                    <FontAwesomeIcon
                      icon={faStop}
                      size="lg"
                      className="iconSend"
                      color="#2C0928"
                    />
                  </button>
                ) : (
                  <button
                    className="send"
                    style={{ padding: '10px' }}
                    onClick={handleSend}
                    disabled={isSendDisabled}
                  >
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      size="lg"
                      className="iconSend"
                      color={!isSendDisabled ? '#2C0928' : '#C0C0C0'}
                    />
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Chat;
