import React, { PropsWithChildren } from 'react';

interface ChatMessageContentProps extends PropsWithChildren {}

export const ChatMessageContent: React.FC<ChatMessageContentProps> = ({
  children,
}) => {
  return (
    <div className="flex-grow overflow-x-auto relative w-full">{children}</div>
  );
};
