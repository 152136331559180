// https://stackoverflow.com/a/1761203
export const calculateContentHeight = (
  textarea: HTMLTextAreaElement,
  lineHeight: number,
  paddingY: number
) => {
  let height = textarea.offsetHeight
  const { scrollHeight } = textarea
  const { height: origHeight, overflow } = textarea.style

  if (height >= scrollHeight) {
    textarea.style.height = height + lineHeight + 'px'
    textarea.style.overflow = 'hidden'

    if (scrollHeight < textarea.scrollHeight) {
      while (
        textarea.offsetHeight >= textarea.scrollHeight &&
        height >= lineHeight
      ) {
        textarea.style.height = (height -= lineHeight) + 'px'
      }

      while (textarea.offsetHeight < textarea.scrollHeight) {
        textarea.style.height = height++ + 'px'
      }

      textarea.style.height = origHeight
      textarea.style.overflow = overflow
    }

    return height - paddingY
  }

  return scrollHeight - paddingY
}
