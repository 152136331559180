import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Tooltip } from 'react-tooltip';
import amLogo from 'assets/AM-genAI-logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPenToSquare,
  faQuestion,
  faComment,
} from '@fortawesome/free-solid-svg-icons';
import { ChatItem, ChatsArray, ChatsMap } from 'pages/Chat/types';
import { ChatListItem } from 'pages/Chat/components/ChatListItem';
import { useAuthContext } from 'common/authentication/AuthProvider';
import { ChatGroupContainer } from 'pages/Chat/Sidebar/ChatGroupContainer';
import { UserIconContainer } from 'common/components/UserIconContainer';
import { SidebarFooterButton } from 'pages/Chat/Sidebar/SidebarFooterButton';

interface SidebarProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onNewChatClick: () => void;
  selectedChatId: string | null;
  onSelectChat: (chatId: string) => void;
  onDeleteChat: (chatId: string) => void;
  // TODO: should we refactor to get rid of this prop?
  triggerTypewriter: boolean;
  chats: ChatsMap;
  onEditChatTitle: (chatId: string, newTitle: string) => void;
  onOpenPopupClick: () => void;
}

export const ChatSidebar: React.FC<SidebarProps> = ({
  isOpen,
  setIsOpen,
  onNewChatClick,
  selectedChatId,
  onSelectChat,
  onDeleteChat,
  triggerTypewriter,
  chats,
  onEditChatTitle,
  onOpenPopupClick,
}) => {
  const sidebarRef = useRef<HTMLDivElement | null>(null);
  const [showChatOptions, setShowChatOptions] = useState<string | null>(null);
  const { userInfo } = useAuthContext();

  //In order to close clicking outside of the sidebar
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isOpen &&
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }

      // Close the options menu if the click is outside
      if (showChatOptions && !event.target.closest('.chat-entry-container')) {
        setShowChatOptions(null);
      }
    };

    // Attach the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Clean up the event listener
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, showChatOptions]);

  //Function to group chats by last day they were interacted with
  const groupedChatsByDate = useMemo(() => {
    const groupedChats: Record<
      'today' | 'yesterday' | 'lastSevenDays' | 'earlier',
      ChatsArray
    > = {
      today: [],
      yesterday: [],
      lastSevenDays: [],
      earlier: [],
    };

    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    const lastWeek = new Date(today);
    lastWeek.setDate(lastWeek.getDate() - 7);

    const chatsArray = Object.values(chats); // Convert object to array for easier mapping

    // Sort chats based on the timestamp of the last message
    const sortedChatsArray = chatsArray.sort((a, b) => {
      const lastMessageA = a.messages[a.messages.length - 1];
      const lastMessageB = b.messages[b.messages.length - 1];
      if (
        lastMessageA &&
        lastMessageB &&
        lastMessageA.timestamp &&
        lastMessageB.timestamp
      ) {
        return (
          new Date(lastMessageB.timestamp).getTime() -
          new Date(lastMessageA.timestamp).getTime()
        );
      }
      return 0;
    });

    sortedChatsArray.forEach(({ chatId, messages, chatTitle }) => {
      if (messages.length === 0) {
        return;
      }
      const lastMessage = messages[messages.length - 1];
      const lastMessageDate = new Date(lastMessage.timestamp);

      const chat: ChatItem = { chatId, messages, chatTitle };

      if (lastMessageDate.toDateString() === today.toDateString()) {
        groupedChats.today.push(chat);
      } else if (lastMessageDate.toDateString() === yesterday.toDateString()) {
        groupedChats.yesterday.push(chat);
      } else if (lastMessageDate > lastWeek) {
        groupedChats.lastSevenDays.push(chat);
      } else {
        groupedChats.earlier.push(chat);
      }
    });

    return groupedChats;
  }, [chats]);

  const handleEditChatTitle = (chatId: string, newTitle: string) => {
    onEditChatTitle(chatId, newTitle);
    setShowChatOptions(null);
  };
  const renderChatListItem = ({ chatId, chatTitle }: ChatItem) => (
    <ChatListItem
      key={chatId}
      chatId={chatId}
      title={chatTitle}
      isSelected={chatId === selectedChatId}
      shouldAnimate={triggerTypewriter && chatId === selectedChatId}
      shouldShowChatOptions={showChatOptions === chatId}
      onDelete={onDeleteChat}
      onChatOptionsClick={setShowChatOptions}
      onSelect={onSelectChat}
      onEditChatTitle={handleEditChatTitle}
    />
  );

  return (
    <div
      ref={sidebarRef}
      className={`sideBar overflow-visible w-[280px] flex flex-col min-h-lvh max-h-lvh ${isOpen ? 'open' : ''}`}
    >
      <div className="flex flex-col p-4 flex-1 overflow-hidden">
        <div
          className="flex items-center mb-6 cursor-pointer rounded-xl transition-colors hover:bg-white hover:bg-opacity-15"
          onClick={onNewChatClick}
          data-tooltip-id="my-tooltip"
          data-tooltip-content="New Chat"
        >
          <Tooltip id="my-tooltip" place="right" className="z-10 bg-black" />

          <img src={amLogo} alt="New Chat" className="" />

          <div className="new-chat-icon-container">
            <FontAwesomeIcon icon={faPenToSquare} className="new-chat-icon" />
          </div>
        </div>
        <div className="overflow-y-auto flex-grow-1">
          {groupedChatsByDate.today.length > 0 && (
            <ChatGroupContainer title="Today">
              {groupedChatsByDate.today.map(renderChatListItem)}
            </ChatGroupContainer>
          )}
          {groupedChatsByDate.yesterday.length > 0 && (
            <ChatGroupContainer title="Yesterday">
              {groupedChatsByDate.yesterday.map(renderChatListItem)}
            </ChatGroupContainer>
          )}
          {groupedChatsByDate.lastSevenDays.length > 0 && (
            <ChatGroupContainer title="Previous 7 Days">
              {groupedChatsByDate.lastSevenDays.map(renderChatListItem)}
            </ChatGroupContainer>
          )}
        </div>
      </div>
      {!!userInfo && (
        <div className="flex-0 p-3 flex items-center justify-items-start ml-1 z-10 overflow-visible">
          <UserIconContainer className="bg-cyan-600">
            {userInfo.initials}
          </UserIconContainer>
          <span className="p-4 text-sm text-white">
            {userInfo.shortenedUsername}
          </span>
        </div>
      )}
      <div className="border-t border-amber-950 flex">
        <SidebarFooterButton icon={faQuestion} onClick={onOpenPopupClick}>
          Tutorial
        </SidebarFooterButton>
        <SidebarFooterButton
          icon={faComment}
          href="https://forms.office.com/r/kxMKhYaJQE"
          isExternalLink
        >
          Feedback
        </SidebarFooterButton>
      </div>
    </div>
  );
};
