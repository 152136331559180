import React from 'react';
//import { useEffect } from 'react';
//import { useMsal } from '@azure/msal-react';
//import { loginRequest } from '../../authConfig'; // Assuming you have this file with your login request configuration
//import Header from '../../components/Header/Header';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faSignInAlt, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
//import { useNavigate } from 'react-router-dom';

import './Login.css';

function Login() {
  //const { instance, accounts } = useMsal();
  //const navigate = useNavigate();

/*
useEffect(() => {
  if (accounts.length === 0) {
    // Trigger the login process automatically when there are no accounts
    instance.loginRedirect(loginRequest).then(response => {
      if (response) {
        navigate('/platform');
      }
    }).catch(e => {
      console.error('Login failed: ', e);
    });
  } else {
    // If there's already an account, navigate to the platform
    navigate('/platform');
  }
}, [instance, accounts, navigate]);
*/


  /*
  const handleLogin = async (loginType) => {
    try {
      if (loginType === "popup") {
        await instance.loginPopup();
      } else if (loginType === "redirect") {
        await instance.loginRedirect();
      }
      // Navigate to /platform on successful login
      navigate('/platform'); // Navigate on successful login
    } catch (e) {
      alert('Authentication failed ', e);
    }
  };
  */


  return (
    <>
      <div>
      </div>
    </>
  );
}

export default Login;
