import { useEffect } from 'react';

declare global {
  interface Window {
    debugValue: unknown;
  }
}

window.debugValue = window.debugValue || {};

export const useDebugOnWindow = (props: Record<string, unknown>) => {
  useEffect(() => {
    window.debugValue = { ...(window.debugValue || {}), ...props };
  }, [props]);
};
