import { ENDPOINTS } from 'common/config/endpoints';
import { getAccessToken } from 'common/authentication/msalInstance';
import { AiModel, UseCase } from 'pages/Chat/types';

export type CreateFormDataProps = {
  chatId: string;
  username: string;
  text: string;
  useCase: UseCase;
  model: AiModel;
  isFirstUserMessage: boolean;
  conversationHistory?: string;
  timestamp: string;
  chatFileNames?: string[];
  abortController?: AbortController;
  threadId?: string;
  fileIds?: string[];
  files?: Array<File>;
};

//URL names for API calls in dev
const urlGenAI = ENDPOINTS.apiGenAI;

export const createFormData = ({
  chatId,
  username,
  text,
  useCase,
  model,
  conversationHistory = ' ',
  isFirstUserMessage,
  timestamp,
  chatFileNames,
  threadId,
  fileIds,
  files,
}: CreateFormDataProps) => {
  const userQuery = text.trim();

  const formData = new FormData();
  formData.append('chatId', chatId);
  formData.append('username', username);
  formData.append('timestamp', timestamp);
  formData.append('useCase', useCase);
  formData.append('userQuery', userQuery);
  formData.append('model', model);
  formData.append('firstMessage', isFirstUserMessage.toString());
  formData.append('conversationHistory', conversationHistory);
  chatFileNames?.forEach((fileName) => {
    formData.append('fileNames', fileName);
  });

  if (useCase === 'assistants_api' && threadId) {
    formData.append('threadId', threadId);
  }
  fileIds?.forEach((fileId) => formData.append('filesId', fileId));
  if (files?.length) {
    addFilesToFormData(formData, files);
  }

  return formData;
};

const addFilesToFormData = (
  formData: FormData,
  files: Array<File>,
  name: string = 'files',
) => {
  files.forEach((file) => {
    formData.append(name, file);
  });
};

export type SendMessageProps = CreateFormDataProps;
export const sendStreamingMessage = async (props: CreateFormDataProps) => {
  // For other use cases, prepare FormData
  const formData = createFormData(props);

  /*
  uploadedFilesComplete.forEach(file => {
    formData.append('files', file);
  });
  */

  //var apiUrl = hasFiles ? `${urlGenAI}/newFiles` : `${urlGenAI}/chatGPT`;
  const apiUrl = `${urlGenAI}/chatGPT`;

  //For testing
  const response = await fetch(apiUrl, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
    body: formData,
    signal: props.abortController?.signal,
  });

  if (!response.ok) {
    throw new Error('Response not okay');
  }

  return response;
};

type SendFileProps = CreateFormDataProps & {
  files: Array<File>;
};
export const sendFile = async ({ files, ...props }: SendFileProps) => {
  const formData = createFormData(props);

  // For other use cases, prepare FormData

  addFilesToFormData(formData, files);

  const apiUrl = `${urlGenAI}/newFiles`;

  //For testing
  const response = await fetch(apiUrl, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
    body: formData,
  });

  if (!response.ok) {
    throw new Error('Response not okay');
  }

  return response;
};

type SendToSqlAgentProps = CreateFormDataProps & {
  files: Array<File>;
};
export const sendToSqlAgent = async ({
  files,
  ...props
}: SendToSqlAgentProps) => {
  const formData = createFormData(props);

  // For other use cases, prepare FormData

  addFilesToFormData(formData, files);

  const apiUrl = `${urlGenAI}/sqlAgent`;

  //For testing
  const response = await fetch(apiUrl, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
    body: formData,
  });

  if (!response.ok) {
    throw new Error('Response not okay');
  }

  return response.json();
};
