import React, {
  useState,
  useEffect,
  PropsWithChildren,
  useContext,
} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { AccountInfo } from '@azure/msal-browser';
import { getInitials, getShortenedUsername } from 'common/authentication/utils';
import { UserInfo } from 'common/authentication/types';
import { loginRequest } from 'common/authentication/msalConfig';

type AuthContextType = {
  userInfo: UserInfo | null;
};

export const AuthContext = React.createContext<AuthContextType>({
  userInfo: null,
});

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const { instance, inProgress, accounts } = useMsal();
  const navigate = useNavigate();
  const location = useLocation();

  const updateUserInfoState = (accountInfo: AccountInfo) => {
    setUserInfo({
      ...accountInfo,
      roles: accounts[0].idTokenClaims?.roles || [],
      initials: getInitials(accountInfo.username),
      shortenedUsername: accountInfo.name
        ? getShortenedUsername(accountInfo.name)
        : accountInfo.username,
    });
  };

  useEffect(() => {
    if (inProgress !== 'none') return; // Avoid running when auth in progress

    const performAuthentication = async () => {
      if (accounts.length > 0) {
        // User is already logged in
        updateUserInfoState(accounts[0]);
      } else if (location.pathname !== '/login') {
        // Redirect to login if not logged in and not on the login page
        try {
          await instance.loginRedirect(loginRequest);
        } catch (error) {
          console.error('Login failed:', error);
        }
      }
    };

    performAuthentication();
  }, [instance, inProgress, accounts, location.pathname]);

  useEffect(() => {
    if (userInfo) {
      navigate('/platform');
    }
  }, [userInfo, navigate]);

  const contextValue = { userInfo };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
