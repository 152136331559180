import React, { Dispatch, SetStateAction } from 'react';
import Select from 'react-select';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faQuestion } from '@fortawesome/free-solid-svg-icons';
// import { SendFeedback } from 'common/components/SendFeedback/SendFeedback';
import { StylesConfig } from 'react-select/dist/declarations/src/styles';
import { AiModel, SelectOption, UseCase } from 'pages/Chat/types';
import { aiModelOptions } from 'pages/Chat/utils/aiModelOptions';
import { useUseCaseOptions } from 'pages/Chat/hooks/useUseCaseOptions';

interface ChatsHeaderProps {
  selectedAiModel: SelectOption<AiModel>;
  onAiModelSelect: Dispatch<SetStateAction<SelectOption<AiModel>>>;
  selectedUseCase: UseCase;
  onUseCaseSelect: Dispatch<SetStateAction<UseCase>>;
  useAssistantApi: boolean;
  setUseAssistantsApi: Dispatch<SetStateAction<boolean>>;
  isChatStarted: boolean;
}

export const ChatsHeader: React.FC<ChatsHeaderProps> = ({
  selectedAiModel,
  onAiModelSelect,
  selectedUseCase,
  onUseCaseSelect,
  useAssistantApi,
  setUseAssistantsApi,
  isChatStarted,
}) => {
  const useCaseOptions = useUseCaseOptions();

  const customDropdownStyles: StylesConfig = {
    option: (provided, state) => ({
      ...provided,
      color: 'black',
      backgroundColor: state.isFocused ? 'lightgray' : 'white',
    }),
    control: (provided) => ({
      ...provided,
      borderRadius: '1.5rem',
    }),
  };

  return (
    <div className="flex justify-between px-4 flex-wrap max-md:justify-center">
      <div className="flex justify-center flex-wrap">
        <div className="mt-4 mb-3 mx-2 text-sm">
          <Select
            styles={customDropdownStyles}
            options={useCaseOptions}
            value={useCaseOptions.find(
              (option) => option.value === selectedUseCase,
            )}
            onChange={(option) =>
              onUseCaseSelect((option as SelectOption<UseCase>).value)
            }
            placeholder="Select use case..."
            isDisabled={useAssistantApi}
          />
        </div>
        <div className="mt-4 mb-3 mx-2 max-md:ml-6 text-sm">
          <label className={`switch-label ${isChatStarted ? 'disabled' : ''}`}>
            <span className="label-text">Analyst CoPilot (Experimental)</span>
            <div className="switch">
              <input
                type="checkbox"
                checked={useAssistantApi}
                onChange={() => setUseAssistantsApi((s) => !s)}
                disabled={isChatStarted}
              />
              <span className="slider round"></span>
            </div>
          </label>
        </div>
      </div>
      <div className="mt-4 mb-3 mx-2 max-md:ml-6 text-sm">
        <Select
          blurInputOnSelect
          styles={customDropdownStyles}
          options={aiModelOptions}
          value={aiModelOptions.find(
            (option) => option.value === selectedAiModel.value,
          )}
          onChange={(value) => {
            if (value) {
              // TODO: verify is this check is needed, or rather to allow null value to be set to state (check state type)
              onAiModelSelect(value as SelectOption<AiModel>);
            }
          }}
        />
      </div>
    </div>
  );
};
