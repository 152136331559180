import React, { useEffect, useState } from 'react';
import { Message } from 'pages/Chat/types';
import amGptLogo from 'assets/am-chat-gpt-logo.svg';
import { useAuthContext } from 'common/authentication/AuthProvider';
import { MessageContent } from 'pages/Chat/components/MessageContent';
import { UserIconContainer } from 'common/components/UserIconContainer';
import { MessageActionButtons } from 'pages/Chat/components/ChatMessage/MessageActionButtons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import { ChatMessageContent } from 'pages/Chat/components/ChatMessage/ChatMessageContent';
import { MessageEditInput } from 'pages/Chat/components/ChatMessage/MessageEditInput';
import { MessageActionButton } from 'pages/Chat/components/ChatMessage/MessageActionButton';

interface ChatMessageProps {
  isFirstMessage: boolean;
  message: Message;
  updateMessageWithTypingEffect: (timestamp: string) => void;
  scrollToBottom: () => void;
  onTypingComplete: () => void;
  onEdit: (message: Message, newValue: string) => void;
  canSubmitEdit: boolean;
}

export const ChatMessage: React.FC<ChatMessageProps> = ({
  isFirstMessage,
  message,
  updateMessageWithTypingEffect,
  scrollToBottom,
  onTypingComplete,
  onEdit,
  canSubmitEdit,
}) => {
  const { userInfo } = useAuthContext();
  const [displayText, setDisplayText] = useState(message.text);
  const isUserMessage = !message.isBot;
  // const runningTimeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    setDisplayText(message.text);
    // WIP - TODO: re-enable the typing effect
    // if (runningTimeout.current) {
    //   clearTimeout(runningTimeout.current);
    // }
    // console.log('\n\n---t---\n', message.text);
    // if (!message.isStreaming) {
    //   setDisplayText(message.text);
    //   return;
    // }
    // // if (message.isBot && !message.hasTypingEffectApplied && !isFirstMessage) {
    // //setIsTyping(true);
    // let currentText = '';
    // let typingCounter = 0; // Counter for characters typed
    // const typeChar = (i: number) => {
    //   // Check if message or message.text is undefined to prevent errors
    //   if (!message || !message.text) {
    //     return;
    //   }
    //
    //   if (i < message.text.length) {
    //     currentText += message.text[i];
    //     setDisplayText(currentText);
    //     typingCounter++;
    //     // Call scrollToBottom after a certain number of characters
    //     if (typingCounter >= 25) {
    //       // Adjust the number as needed
    //       scrollToBottom();
    //       typingCounter = 0; // Reset counter
    //     }
    //     runningTimeout.current = setTimeout(() => typeChar(i + 1), 2); // Adjust speed as needed
    //   } else {
    //     //setIsTyping(false);
    //     onTypingComplete();
    //     // Call updateMessageWithTypingEffect here to update the message state
    //     updateMessageWithTypingEffect(message.timestamp);
    //   }
    // };
    // typeChar(0);
    // }
  }, [
    message,
    updateMessageWithTypingEffect,
    isFirstMessage,
    scrollToBottom,
    onTypingComplete,
    setDisplayText,
  ]);

  // Original implementation
  //   useEffect(() => {
  //     // setDisplayText(text);
  //     if (!isStreaming) {
  //       setDisplayText(text);
  //       return;
  //     }
  //     //setIsTyping(true);
  //     let currentText = '';
  //     let typingCounter = 0; // Counter for characters typed
  //     const typeChar = (i: number) => {
  //       // Check if message or message.text is undefined to prevent errors
  //       if (!text) {
  //         return;
  //       }
  //
  //       if (i < text.length) {
  //         currentText += text[i];
  //         setDisplayText(currentText);
  //         typingCounter++;
  //         // Call scrollToBottom after a certain number of characters
  //         if (typingCounter >= 25) {
  //           // Adjust the number as needed
  //           scrollToBottom();
  //           typingCounter = 0; // Reset counter
  //         }
  //         setTimeout(() => typeChar(i + 1), 2); // Adjust speed as needed
  //       } else {
  //         //setIsTyping(false);
  //         onTypingComplete();
  //         // Call updateMessageWithTypingEffect here to update the message state
  //         updateMessageWithTypingEffect(timestamp);
  //       }
  //     };
  //     typeChar(0);
  //   }, [isStreaming, text, timestamp, isFirstMessage, scrollToBottom]);

  const [isEditing, setIsEditing] = useState(false);
  const [editTextValue, setEditTextValue] = useState<string>(message.text);

  // While switching between chats, the message.id is changed, and we need to hide and reset the editing values
  useEffect(() => {
    setIsEditing(false);
    setEditTextValue(message.text);
  }, [message.id]);

  const canEdit = !message.isBot;

  const handleCancelEdit = () => {
    setEditTextValue(message.text);
    setIsEditing(false);
  };

  const handleSubmitEdit = (value: string) => {
    if (!canSubmitEdit) {
      return;
    }
    onEdit(message, value);
    handleCancelEdit();
  };

  const isTextEdited = displayText !== editTextValue;
  const isSubmitDisabled = !canSubmitEdit || !isTextEdited;

  return (
    <div
      className={`m-4 py-4 px-6 lg:px-12 flex items-start ${isUserMessage ? '' : 'bg-white'}`}
    >
      <div className="mr-4 flex-grow-0">
        {isUserMessage ? (
          <UserIconContainer className="bg-cyan-800">
            {userInfo?.initials}
          </UserIconContainer>
        ) : (
          <UserIconContainer>
            <img className="object-cover" src={amGptLogo} alt="A&M ChatGPT" />
          </UserIconContainer>
        )}
      </div>
      <div className="w-full min-h-12 flex flex-col">
        {isEditing ? (
          <ChatMessageContent>
            <MessageEditInput
              onSubmit={handleSubmitEdit}
              onCancel={handleCancelEdit}
              onChange={setEditTextValue}
              value={editTextValue}
              canSubmitEdit={!isSubmitDisabled}
            />
            <MessageActionButtons>
              <MessageActionButton
                disabled={isSubmitDisabled}
                onClick={() => handleSubmitEdit(editTextValue)}
              >
                <FontAwesomeIcon
                  icon={faCheck}
                  size="sm"
                  className={
                    !isSubmitDisabled ? 'text-blue-700' : 'text-gray-400'
                  }
                />
              </MessageActionButton>
              <MessageActionButton onClick={handleCancelEdit}>
                <FontAwesomeIcon icon={faTimes} size="sm" color="#888" />
              </MessageActionButton>
            </MessageActionButtons>
          </ChatMessageContent>
        ) : (
          <ChatMessageContent>
            <MessageContent>{displayText}</MessageContent>
            <MessageActionButtons>
              {canEdit && (
                <MessageActionButton onClick={() => setIsEditing(true)}>
                  <FontAwesomeIcon icon={faEdit} size="sm" color="#888" />
                </MessageActionButton>
              )}
            </MessageActionButtons>
          </ChatMessageContent>
        )}
      </div>
    </div>
  );
};
