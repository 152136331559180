import React, { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';

interface MessageActionButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {}

export const MessageActionButton: React.FC<MessageActionButtonProps> = ({
  className = '',
  ...props
}) => {
  return <button className={`p-1 ml-2 ${className}`} {...props} />;
};
