import { useAuthContext } from 'common/authentication/AuthProvider';
import { useEffect, useState } from 'react';
import { SelectOption, UseCase } from 'pages/Chat/types';

export const useUseCaseOptions = () => {
  // Define the base use case options
  const baseUseCaseOptions: Array<SelectOption<UseCase>> = [
    { label: 'Fast File Processing', value: 'fast' },
    { label: 'High-res File Processing', value: 'high-res' },
    { label: 'Automated Extraction', value: 'automated' },
    // { label: 'High-Res Processing', value: 'ocr' },
  ];

  const { userInfo } = useAuthContext();
  //Select config options
  const [useCaseOptions, setUseCaseOptions] =
    useState<Array<SelectOption<UseCase>>>(baseUseCaseOptions);

  // useEffect hook to update useCaseOptions when userRoles changes
  useEffect(() => {
    if (userInfo?.roles?.includes('SQLReader')) {
      const updatedUseCaseOptions: Array<SelectOption<UseCase>> = [
        ...baseUseCaseOptions,
        {
          label: 'Procurement (SQL)',
          value: 'procurementSql',
        },
      ];
      setUseCaseOptions(updatedUseCaseOptions);
    }
  }, [userInfo?.roles]); // Dependency array includes userRoles

  return useCaseOptions;
};
