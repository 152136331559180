import React, { createContext, useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';

import ProtectedRoute from 'common/components/ProtectedRoute'; // Ensure this path is correct
import Login from './pages/Login/Login';
import Chat from './pages/Chat/Chat';
import './App.css';
import { AuthProvider } from 'common/authentication/AuthProvider';
import { loginRequest } from 'common/authentication/msalConfig';
import { useRestructureChats } from 'pages/Chat/ui-migrations/useRestructureChats';

type AppContextProps = {
  username?: string;
  userRoles?: string[];
  token: string;
  email?: string;
};

// TODO: remove AppContext since the user info can be fetched from AuthContext.
//  The token should not be fetched in a react state
export const AppContext = createContext<AppContextProps>({ token: '' });

function AppContent() {
  const [username, setUsername] = useState<string | undefined>('');
  const [userRoles, setUserRoles] = useState<string[]>([]);
  const [email, setEmail] = useState<string | undefined>('');
  const [token, setToken] = useState('');
  const { instance, accounts, inProgress } = useMsal();

  const acquireToken = useCallback(async () => {
    const response = await instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0],
    });
    // After acquiring the token:
    setToken(response.accessToken);
  }, [instance, accounts]);

  useEffect(() => {
    if (accounts.length > 0) {
      setUsername(accounts[0].name);
      setEmail(accounts[0].username);
      setUserRoles(accounts[0].idTokenClaims?.roles || []);
      if (inProgress === 'none' && accounts.length > 0) {
        acquireToken();
      }
    }
  }, [accounts, acquireToken, inProgress]);

  const contextValue = {
    username,
    userRoles,
    token,
    email,
  };

  const isChatRestructureReady = useRestructureChats();

  if (!isChatRestructureReady) {
    return null;
  }

  return (
    <AppContext.Provider value={contextValue}>
      <Router>
        <AuthProvider>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route
              path="/platform"
              element={
                <ProtectedRoute>
                  <Chat />
                </ProtectedRoute>
              }
            />
          </Routes>
        </AuthProvider>
      </Router>
    </AppContext.Provider>
  );
}

function App() {
  return <AppContent />;
}

export default App;
