import React, { KeyboardEventHandler, useEffect, useRef } from 'react';
import { captureKeyboardInputActions } from 'pages/Chat/utils/forms';
import { useTextareaRows } from 'pages/Chat/hooks/useTextareaRows';

interface MessageEditInputProps {
  value: string;
  onChange: (value: string) => void;
  onSubmit: (value: string) => void;
  onCancel: () => void;
  canSubmitEdit: boolean;
}

export const MessageEditInput: React.FC<MessageEditInputProps> = ({
  value,
  onChange,
  onCancel,
  onSubmit,
  canSubmitEdit,
}) => {
  const inputRef = useRef<HTMLTextAreaElement | null>(null);

  const { rows } = useTextareaRows({ textAreaRef: inputRef, text: value });

  useEffect(() => {
    const element = inputRef.current;
    if (element) {
      element.focus();
      element.selectionStart = element.selectionEnd = element.value.length;
    }
  }, []);

  const handleKeyDown: KeyboardEventHandler<HTMLTextAreaElement> = (event) => {
    captureKeyboardInputActions({
      event,
      canSubmit: canSubmitEdit,
      onCancel,
      onSubmit: () => onSubmit(value),
    });
  };

  return (
    <textarea
      ref={inputRef}
      value={value}
      rows={rows}
      onChange={(evt) => onChange(evt.target.value)}
      onKeyDown={handleKeyDown}
      className="p-1 outline-0 border border-gray-200 rounded-md text-sm w-full"
    />
  );
};
